



































import { ref as _ref } from "@vue/composition-api";
import router from "@/router";
import Api, { parse, TaskResult } from "@/select2/taskresult";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const routeId = router.currentRoute.params.id;

  let loading = _ref(true);

  let foundTask = _ref(true);

  let taskResult = _ref((null as TaskResult | null));

  async function load() {
    try {
      const data = await Api.get(routeId);
      taskResult.value = data;
    } catch (e) {
      foundTask.value = false;
    } finally {
      loading.value = false;
    }
  }

  load();
  return {
    routeId,
    loading,
    foundTask,
    taskResult
  };
};

export default __sfc_main;
